<template>
  <div style="height:100%">
    <comp-table :tableData="tableData" :tableHeader="tableHeader" :pageSize='pageSize' :total='total' @current-events="getList" @save-current="savePage" v-loading='isLoading' v-if="initStatus" :hideSinglePage='false'>
    </comp-table>
    <nodata :message="'暂无相关成员'" h="400" v-else />
    <delte-dialog :visible='deleteVis' :cancelDialog='cancelDialog' :confirmDialog='confirmDialog' :rowData='rowData' :isdeling='isdeling'/>
    <transfer-dialog :visible='transferVis' :cancelDialog='cancelTransfer' :confirmDialog='confirmTransfer' :rowData='rowData' :errors='errors' :errorMsg='errorMsg' :isdeling='isdeling'/>
    <commit-dialog :visible='commitVis' :cancelDialog='cancelCommit' :confirmDialog='confirmCommit' :rowData='rowData' :isdeling='isdeling'/>
    <quit-dialog :visible='quitVis' :cancelDialog='cancelQuit' :confirmDialog='confirmQuit' :rowData='rowData' :isdeling='isdeling'/>

  </div>
</template>

<script>
  import delteDialog from './dialog/delte';
  import quitDialog from './dialog/quit';
  import transferDialog from './dialog/transfer';
  import commitDialog from './dialog/commit';
  export default {
    props: ['value'],
    name: "complexTable",
    components: {
      delteDialog,
      transferDialog,
      commitDialog,
      quitDialog
    },
    data() {
      return {
        tableData: [], // 请求到的表格数据
        tableHeader: [
          // 表头信息
          {
            prop: "username",
            center: 'left',
            label: "用户",
          },
          {
            prop: "phone",
            label: "手机号",
            center: 'left',
          },
          {
            prop: "roleName",
            label: "权限",
            center: 'left',
          },
          {
            prop: "operation",
            label: "操作",
            center: 'left',
            operation:
              [
                { permissions : true , name: "移交",  clickFun: this.transfer, auth:'permissions:turnover'},
                { name: "编辑", clickFun: this.editDomain , auth:'permissions:edit'},
                { name: "删除", clickFun: this.showCancel , auth:'permissions:delete'}, // makeSure: true,
                { name: "退出", clickFun: this.showQuit , auth:'permissions:delete'},
              ]
          },
        ],
        multipleSelection: [],

        //分页查询
        page: 1,
        data: [],
        total: 0,
        pageSize: 10,
        current: '',
        isLoading: false,
        initStatus: false,

        //提示窗
        deleteVis: false,
        rowData: {},
        quitVis: false,
        transferVis : false,
        commitVis : false,
        errors: false,
        errorMsg :'',

        isdeling : false,
      };
    },
    methods: {
      // 所有实例列表
      savePage(val) {
        this.current = val
      },
      getList(val) {
        this.isLoading = true
        this.$store.dispatch("FindPermissionsList", {
          // name: this.value, //模糊查询
          companyId : this.getToken('companyId'),
          index: val ? val : this.page,
          rows: this.pageSize,
        }).then(res => {
          this.isLoading = false
          if (res.success) {
            if(res.data.length > 0){
              this.initStatus = true;
            }
            this.total = res.data.length;
            this.tableData = res.data;
            // this.total = res.data.count;
            // this.tableData = res.data.list;
          } else {
            this.initStatus = false
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          this.isLoading = false
        })
      },
      sortEvents(type) {
        console.log(type);
      },
      showCancel(item) {
        this.rowData = item;
        this.deleteVis = true;
      },
      showQuit(item){
        this.rowData = item;
        this.quitVis = true;
      },
      cancelQuit(item){
        this.quitVis = false;
      },
      cancelDialog() {
        this.deleteVis = false;
      },
      confirmQuit() {
        this.quitDomain(this.rowData)
      },
      confirmDialog() {
        this.deleteDomain(this.rowData)
      },
      // 移交权限
      transfer(row){
        this.rowData = {...row,remove : row.phone,receive:'',nowCompany: this.getToken('nowCompany')};
        this.transferVis = true;
      },
      cancelTransfer(){
        this.rowData = {};
        this.transferVis = false;
        this.errors = false;
        this.errorMsg = '';
      },
      confirmTransfer(){  //打开确认提交
        this.isdeling = true;
        this.$store.dispatch("VerifyAcc", { receiverPhone : this.rowData.receive},).then(res => {
          if (res.success) {
            this.transferVis = false;
            this.commitVis = true;
            this.isdeling = false;
          } else {
            this.errors = true;
            this.errorMsg = '接收方账号不存在';
            this.isdeling = false;
          }
        }).catch(err => {
        })
      },
      cancelCommit(){
          this.commitVis = false;
          this.transferVis = true;
      },
      confirmCommit(){ //移交权限确认提交框
        this.isdeling = true;
        this.$store.dispatch("TransferAcc", {  companyId: this.getToken('companyId'), userId: this.rowData.userId , receiverPhone : this.rowData.receive},).then(res => {
          if (res.success) {
            this.isdeling = false;
            this.commitVis = false;
            this.transferVis = false;
            this.setToken('companyId','');
            einvAlert.success("提示",'移交成功');
            // localStorage.setItem('sideLink', '/home');
            setTimeout(() => {
              window.location.reload();
            }, 1000)
          } else {
            this.isdeling = false;
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
        })


      },
      //编辑
      editDomain(row) {
        this.$router.push({
          path: "/permission/permissions/edit",
          query: { id: row.userId }
        })
      },
      // 删除
      deleteDomain(item) {
        this.isdeling = true;
        this.$store.dispatch("DelPermissions", {  companyId: this.getToken('companyId'), userId: item.userId },).then(res => {
          if (res.success) {
            this.isdeling = false;
            this.deleteVis = false;
            einvAlert.success("提示",'删除成功');
            this.getList();
          } else {
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {

        })
      },
      // 退出
      quitDomain(item) {
        this.isdeling = true;
        this.$store.dispatch("DelPermissions", {  companyId: this.getToken('companyId'), userId: item.userId },).then(res => {
          if (res.success) {
            this.isdeling = false;
            this.quitVis = false;
            this.setToken('companyId','')
            this.setToken('nowCompany','')
            einvAlert.success("提示",'退出成功');
            window.location.reload()
          } else {
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
        })
      },


    },
    mounted() {
      var _this = this
      var id =setInterval(function (){
        var companyId = _this.getToken('companyId')
        if(companyId){
          _this.$parent.nowCompany = _this.getToken('nowCompany')
          _this.getList();
          clearInterval(id)
        }
      },100)
    },
  };
</script>

<style scoped>
  .filter-container {
    background: #fff;
    margin-top: 20px;
    padding: 20px 20px 0px 20px;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .filter-item {
    margin-left: 12px;
    width: 130px;
    margin-bottom: 10px;
  }
  .btn-item {
    height: 32px;
    margin-left: 12px;
    vertical-align: super;
  }

</style>
